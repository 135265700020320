import {
  json,
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import {
  BlockStack,
  Button,
  Card,
  EmptyState,
  InlineStack,
  Layout,
  Page,
  Text,
} from "@shopify/polaris";
import * as Sentry from "@sentry/remix";
import Logo from "./assets/images/logo.png";
import { AppProvider } from "@shopify/shopify-app-remix/react";
import { IntercomProvider } from "react-use-intercom";
import polarisStyles from "@shopify/polaris/build/esm/styles.css?url";

export const links = () => [{ rel: "stylesheet", href: polarisStyles }];

export const loader = async ({ request }) => {
  // Check for maintenance mode
  const maintenance = process.env.maintenance;
  const url = new URL(request.url); // currentPath prevents  infinite redirects
  const currentPath = url.pathname;
  if (
    maintenance !== undefined &&
    maintenance !== "" &&
    currentPath !== "/maintenance"
  ) {
    throw redirect("/maintenance");
  }
  return json({
    maintenance,
  });
};

export default function App() {
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
        {/* clarity tracker */}
        {process.env.NODE_ENV === "production" && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
             })(window, document, "clarity", "script", "qm43jdxy3q");`,
            }}
          ></script>
        )}
      </head>
      <body>
        <IntercomProvider appId="vlh5nk1z">
          <Outlet />
        </IntercomProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  Sentry.captureRemixErrorBoundaryError(error);
  return (
    <html>
      <head>
        <title>Something went wrong</title>
        <Meta />
        <Links />
      </head>
      <body>
        <AppProvider>
          <Page>
            <Layout>
              <Layout>
                {error.status === 404 ? (
                  <NotFound error={error} />
                ) : (
                  <Card>
                    <EmptyState
                      heading="Something went wrong"
                      action={{ content: "Home", url: "/app" }}
                      image={Logo}
                    ></EmptyState>
                    {process.env.NODE_ENV === "development" && (
                      <Text as="pre" alignment="start">
                        {error.stack}
                      </Text>
                    )}
                  </Card>
                )}
              </Layout>
            </Layout>
          </Page>
        </AppProvider>
        <Scripts />
      </body>
    </html>
  );
};

function NotFound({ error }) {
  return (
    <Card>
      <BlockStack inlineAlign="center" gap={"400"}>
        <img src={Logo} alt="Logo" />
        <Text variant="headingLg">Page Not Found</Text>
        <Button url="/app" variant="primary" size="large">
          Home
        </Button>
      </BlockStack>
      {process.env.NODE_ENV === "development" && <pre>{error.stack}</pre>}
    </Card>
  );
}
